import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('veiculos', pagination)
  },
  insert (veiculo) {
    return Api().post('veiculo', veiculo)
  },
  update (veiculo) {
    return Api().put('veiculo', veiculo)
  },
  delete (veiculo) {
    return Api().delete(`veiculo?id=${veiculo.id}`, veiculo)
  },
  findById (veiculo) {
    const url = `veiculo/${veiculo.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().get('veiculoParam', param)
  }
}
