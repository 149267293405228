import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('pessoas', pagination)
  },
  insert (pessoa) {
    return Api().post('pessoa', pessoa)
  },
  update (pessoa) {
    return Api().put('pessoa', pessoa)
  },
  delete (pessoa) {
    return Api().delete(`pessoa?id=${pessoa.id}`, pessoa)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('pessoasParam', param)
  },
  findById (pessoa) {
    const url = `pessoa/${pessoa.id}`
    return Api().get(url)
  },
  countTotalPorCondominio (idCondominio) {
    const url = `pessoa/contar/${idCondominio}`
    const api = Api()
    return api.get(url)
  }
}
