<template>
  <crud-form
    :tabs-cabecalho="novasTabs"
    :titulo-pagina-tab="tituloPaginaTab"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :tab-selecionada.sync="tab"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
  >
    <v-tabs-items v-model="tab">
      <v-tab-item :value="'tab-dados-pessoais'">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="objEditar.nome"
                :rules="[required]"
                :label="$t('PessoasForm.nome')"
                required
                prepend-icon="mdi-account-edit"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="objEditar.apelido"
                :label="$t('PessoasForm.apelido')"
                prepend-icon="mdi-account-edit"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="objEditar.email"
                :label="$t('PessoasForm.email')"
                :hint="$t('PessoasForm.serausadocomologin')"
                persistent-hint
                prepend-icon="mdi-account-edit"
              />
            </v-col>
            <v-col
              cols="5"
              class="hidden-md-and-up text-left"
            >
              <span>{{ $vuetify.lang.t('$vuetify.PessoasForm.tipoDePessoa') }}</span>
            </v-col>
            <v-col
              cols="7"
              class="hidden-md-and-up text-right"
            >
              <v-radio-group
                v-model="objEditar.tipoPessoa"
                :mandatory="true"
                row
              >
                <v-radio
                  :label="$t('PessoasForm.fisica')"
                  value="F"
                />
                <v-radio
                  :label="$t('PessoasForm.juridica')"
                  value="J"
                />
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="hidden-sm-and-down"
            >
              <span>{{ $vuetify.lang.t('$vuetify.PessoasForm.tipoDePessoa') }}</span>
              <v-radio-group
                v-model="objEditar.tipoPessoa"
                :mandatory="true"
                row
              >
                <v-radio
                  :label="$t('PessoasForm.fisica')"
                  value="F"
                />
                <v-radio
                  :label="$t('PessoasForm.juridica')"
                  value="J"
                />
              </v-radio-group>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <v-text-field
                v-model="objEditar.bloco"
                :label="$t('PessoasForm.bloco')"
                prepend-icon="mdi-business"
              />
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <v-text-field
                v-model="objEditar.apartamento"
                :label="$t('PessoasForm.apartamento')"
              />
            </v-col>
            <v-col
              v-if="objEditar.tipoPessoa === 'J'"
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="objEditar.razaoSocial"
                :label="$t('PessoasForm.razaoSocial')"
                prepend-icon="mdi-factory"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="objEditar.cpfOuCnpj"
                :label="objEditar.tipoPessoa === 'F'? $t('PessoasForm.cpf') : $t('PessoasForm.cnpj')"
                prepend-icon="mdi-account-card-details"
              />
            </v-col>
            <v-col
              cols="12"
              sm="5"
            >
              <v-text-field
                v-model="objEditar.rg"
                :label="$t('PessoasForm.rg')"
                prepend-icon="mdi-account-card-details"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="objEditar.orgaoEmissor"
                :label="$t('PessoasForm.orgaoEmissor')"
                prepend-icon="mdi-account-card-details"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="objEditar.dataRG"
                :label="$t('PessoasForm.dataEmissao')"
                prepend-icon="mdi-account-card-details"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="objEditar.nomePai"
                :label="$t('PessoasForm.nomePai')"
                prepend-icon="mdi-account-edit"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="objEditar.nomeMae"
                :label="$t('PessoasForm.nomeMae')"
                prepend-icon="mdi-account-edit"
              />
            </v-col>
            <v-alert
              v-model="alertValidacao"
              dismissible
              type="error"
              transition="scale-transition"
            >
              {{ error }}
            </v-alert>
          </v-row>
        </v-container>
      </v-tab-item>
      <!-- fim dados pessoais -->
      <!-- inicio endereço -->
      <v-tab-item :value="'tab-enderecos'">
        <endereco :id-pessoa="objEditar.id" />
      </v-tab-item>
      <!-- fim endereço -->
      <!-- inicio veiculos -->
      <v-tab-item :value="'tab-veiculos'">
        <veiculo :id-pessoa="objEditar.id" />
      </v-tab-item>
      <!-- fim veiculos -->
      <!-- inicio animais -->
      <v-tab-item :value="'tab-animais'">
        <!-- <animal :idPessoa="objEditar.id">

                                </animal> -->
        olá mundo
      </v-tab-item>
      <!-- fim animais -->
    </v-tabs-items>
    <span
      v-if="tab !== 'tab-dados-pessoais'"
      slot="botoes"
    />
  </crud-form>
</template>

<script>

  import Servico from '@/services/PessoasService'
  import Endereco from './Endereco'
  import Veiculo from './Veiculo'

  export default {
    components: {
      Endereco,
      Veiculo
    },
    data: () => ({
      mensagemPerguntaRemocao: 'Deseja remover esta pessoa?',
      objPadrao: {
        nome: '',
        apelido: '',
        tipoPessoa: 'F',
        cpfOuCnpj: '',
        razaoSocial: '',
        rg: '',
        orgaoEmissor: '',
        dataRG: null,
        nomePai: '',
        nomeMae: '',
        bloco: 0,
        apartamento: 0,
        id: 0,
        email: ''
      },
      objEditar: {
        nome: '',
        apelido: '',
        tipoPessoa: 'F',
        cpfOuCnpj: '',
        razaoSocial: '',
        rg: '',
        orgaoEmissor: '',
        dataRG: null,
        nomePai: '',
        nomeMae: '',
        bloco: 0,
        apartamento: 0,
        id: 0,
        email: ''
      },
      required: value => !!value || 'Campo Obrigatório',
      isEditting: false,
      error: null,
      validForm: false,
      alertValidacao: false,
      mostraBotaoSalvarContinuar: false,
      tab: null,
      tabCabecalhos: []
    }),
    computed: {
      tituloPaginaTab () {
        return this.$t('PessoasForm.tituloPagina')
      },
      tabCabecalhosValendo () {
        var tabValendo = this.tabCabecalhos.filter((guia) => {
          return guia.mostraCabecalho
        })
        return tabValendo
      },
      novasTabs () {
        let retorno = [
          {
            texto: this.$t('PessoasForm.tab-dados-pessoais') || 'Dados Pessoais',
            link: '#tab-dados-pessoais',
            icone: 'mdi-account',
            mostraCabecalho: true
          },
          {
            texto: this.$t('PessoasForm.tab-enderecos') || 'Endereços',
            link: '#tab-enderecos',
            icone: 'mdi-account-card-details',
            mostraCabecalho: this.isEditting
          },
          {
            texto: this.$t('PessoasForm.tab-veiculos') || 'Veículos',
            link: '#tab-veiculos',
            icone: 'mdi-car',
            mostraCabecalho: this.isEditting
          },
          {
            texto: this.$t('PessoasForm.tab-animais') || 'Animais',
            link: '#tab-animais',
            icone: 'mdi-dog',
            mostraCabecalho: this.isEditting
          }
        ]

        retorno = retorno.filter((guia) => {
          return guia.mostraCabecalho
        })

        return retorno
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      traduzir (value) {
        try {
          return this.$t(value)
        } catch (error) {
          return value
        }
      },
      remover () {
        const registro = this.objEditar
        try {
          Servico.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Pessoa removida com sucesso'
              })
              this.buscar()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um condomínio:\n ->' + error
          })
        }
      },
      saveContinueEvent () {
        this.inserir(this.objEditar, true)
      },
      closeDialog () {
        this.buscar()
      },
      saveEventHandler () {
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {
          Servico.update(registro)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: 'Pessoa salvo com sucesso'
                })
                this.buscar()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      buscar () {
        this.$router.push('/pessoas')
      },
      inserir (pessoa, continuar) {
        try {
          Servico.insert(pessoa).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Pessoa salva com sucesso'
              })
              if (continuar) {
                this.editar(response.data.id)
              } else {
                this.buscar()
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um condomínio:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then(registro => {
            if (registro.id) {
              this.objEditar = registro
              this.isEditting = true
            } else if (registro.data.id) {
              this.objEditar = registro.data
              this.isEditting = true
            } else {
              this.isEditting = false
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
