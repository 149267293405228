export default {
  arrayCores: [
    { cor: 'Preta', codigo: 'black' },
    { cor: 'Branca', codigo: 'white' },
    { cor: 'Cinza-escura', codigo: 'darkgray' },
    { cor: 'Cinza', codigo: 'gray' },
    { cor: 'Bordô', codigo: 'blueviolet' },
    { cor: 'Vermelha', codigo: 'red' },
    { cor: 'Azul-Escura', codigo: 'darkblue' },
    { cor: 'Azul', codigo: 'blue' },
    { cor: 'Verde-Escura', codigo: 'darkgreen' },
    { cor: 'Verde', codigo: 'green' },
    { cor: 'Marrom', codigo: 'brown' },
    { cor: 'Bege', codigo: 'beige' },
    { cor: 'Laranja', codigo: 'orange' },
    { cor: 'Amarela', codigo: 'yellow' },
    { cor: 'Roxo', codigo: 'purple' },
    { cor: 'Rosa', codigo: 'pink' }],
  retornaCoresVeiculosPadrao () {
    return this.arrayCores
  },
  retornaCor (codigo, arrayCores) {
    if (!codigo) {
      return null
    }
    if (!arrayCores || arrayCores.length === 0) {
      arrayCores = this.arrayCores
    }
    for (let i = 0; i < arrayCores.length; i++) {
      const cor = arrayCores[i]
      if (codigo === cor.codigo) {
        return cor
      }
    }
    return null
  }
}
