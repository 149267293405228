<template>
  <v-col cols="12">
    <crud-filho-list
      :mostra-tabela="!formulario"
      :headers="headers"
      :items="items"
      :paginacao.sync="paginacao"
      :server-items-length="totalRegistros"
      :loading="loading"
      :hide-default-header="false"
      @novo-event="novo"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <td
          mask="AAA-####"
          class="justify-left"
        >
          {{ props.item.item.placa }}
        </td>
        <td class="justify-center">
          {{ props.item.item.modelo }}
        </td>
        <td class="justify-center">
          {{ props.item.item.marca }}
        </td>
        <td class="justify-center">
          <visualiza-cor
            :display-div="'flex'"
            :item="props.item.cor | retornaCorPorCodigo"
          />
        </td>
      </template>
    </crud-filho-list>
    <crud-filho-form
      :mostra-formulario="formulario"
      :is-editting="isEditting"
      :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
      :alerta-validacao="alertValidacao"
      :mensagem-erro="error"
      @closeDialogEvent="closeDialog"
      @saveEvent="saveEvent"
      @save-continue-event="saveEvent"
      @deleteEvent="remover"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="objEditar.placa"
          label="Placa"
          mask="AAA-####"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="objEditar.modelo"
          label="Modelo"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="objEditar.marca"
          label="Marca"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="corVeiculo"
          :items="coresVeiculo"
          prepend-icon="mdi mdi-palette"
          label="Cor"
        >
          <template
            slot="item"
            slot-scope="{item}"
          >
            <visualiza-cor :item="item" />
          </template>
          <template
            slot="selection"
            slot-scope="{item}"
          >
            <visualiza-cor :item="item" />
          </template>
        </v-select>
      </v-col>
    </crud-filho-form>
  </v-col>
</template>

<script>

  import VeiculosService from '@/services/VeiculosService'
  import VisualizaCor from '@/components/VisualizaCor'
  import CoresService from '@/services/CoresService'

  export default {
    components: {
      VisualizaCor
    },
    props: {
      idPessoa: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        alertValidacao: false,
        autoCompleteModel: null,
        autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: '',
        loadingVeiculo: false,
        headers: [
          {
            text: 'Placa',
            value: 'placa'
          },
          {
            text: 'Modelo/Marca',
            value: 'marca',
            class: 'hidden-md-and-up'
          },
          {
            text: 'Modelo',
            value: 'modelo',
            class: 'hidden-sm-and-down'
          },
          {
            text: 'Marca',
            value: 'marca',
            class: 'hidden-sm-and-down'
          },
          {
            text: 'Cor',
            value: 'cor',
            sortable: false
          }
        ],
        items: [],
        paginacao: null,
        loading: false,
        coresVeiculo: CoresService.arrayCores,
        totalRegistros: 0,
        objPadrao: {
          placa: '',
          marca: '',
          modelo: '',
          ano: 0,
          cor: ''
        },
        objEditar: {
          placa: '',
          marca: '',
          modelo: '',
          ano: 0,
          cor: ''
        },
        mensagemPerguntaRemocao: 'Deseja remover este veículo?',
        isEditting: false,
        error: null,
        required: value => !!value || 'Campo Obrigatório',
        formulario: false
      }
    },
    computed: {
      corVeiculo: {
        get: function () {
          return CoresService.retornaCor(this.objEditar.cor)
        },
        set: function (val) {
          this.objEditar.cor = val.codigo
        }
      }
    },
    watch: {
      idPessoa (valor) {
        if (valor > 0) {
          this.buscar()
        }
      }
    },
    methods: {
      buscaVeiculo () {
        this.alertValidacao = false
        this.error = 'null'
        let placa = this.objEditar.placa
        if (!placa || placa.length < 8) {
          this.alertValidacao = true
          this.error = 'Campo placa não preenchido corretamente'
          return
        }
        placa = placa.replace('-', '')
        if (placa.length !== 8) {
          this.alertValidacao = true
          this.error = 'Campo placa não preenchido corretamente'
        }
      },
      novo () {
        this.objEditar = Object.assign({}, this.objPadrao)
        this.formulario = true
        this.editedIndex = -1
      },
      editar (veiculo) {
        this.editedIndex = this.itens.indexOf(veiculo)
        this.objEditar = Object.assign({}, veiculo)
        this.dialog = true
        this.isEditting = true
        this.formulario = true
      },
      closeDialog () {
        this.formulario = false
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)
        this.autoCompleteModel = null
        this.autoCompleteItens = []
      },
      saveEvent () {
        this.formulario = false
        this.isEditting = false
        this.objEditar.PessoaId = this.idPessoa

        if (this.editedIndex > -1) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
        this.closeDialog()
      },
      remover () {
        const veiculo = this.objEditar
        try {
          VeiculosService.delete(veiculo).then(response => {
            this.$root.$emit('mostra_dialogo_alerta', {
              dialogoTitulo: 'Sucesso',
              dialogoDescricao: 'Veículo removido com sucesso'
            })
            this.closeDialog()
            this.buscar()
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Ocorreu um erro ao remover um endereço:\n ->' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover o endereço:\n ->' + error
          })
        }
      },
      inserir (registro) {
        try {
          VeiculosService.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Veículo salvo com sucesso'
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Erro ao inserir o Veículo ' + error
          })
        }
      },
      atualizar (registro) {
        try {
          VeiculosService.update(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Veículo salvo com sucesso'
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Erro ao atualizar o Veículo ' + error
          })
        }
      },
      buscar () {
        try {
          const param = {
            pagination: this.pagination,
            idPessoa: this.idPessoa
          }
          this.loading = true

          VeiculosService.findAll(param)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
                return
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
                return
              }
              this.itens = response.data.rows
              this.totalRegistros = response.data.count
              this.loading = false
            })
            .catch(err => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Erro ao comunicar com o servidor, avise os Administradores -> ' + err
              }
              )
            })
            .finally(() => {
              this.loading = false
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Erro na busca de Endereços' + error
          })
          this.loading = false
        }
      }
    }
  }
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
